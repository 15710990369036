import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Card = styled.div`
  padding: 1rem 1rem;
  //    margin:  auto;
`

const LogoCard = ({ logoFile, altText, siteLink }) => (
  <Card>
    <a href={siteLink} target="_blank" rel="noopener noreferrer">
      <Img fluid={logoFile} alt={altText} />
    </a>
  </Card>
)

export default LogoCard
