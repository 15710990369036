import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Gallery from "../components/gallery"
import styled from "styled-components"

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 80%;
  padding: 1.45rem 1.45rem;
`

const BrandsPage = () => (
  <Layout>
    <SEO title="Brands" />
    <StyledContainer>
      <Gallery category="Beauty Skincare" />
      <Gallery category="Beauty Haircare" />
      <Gallery category="Health & Personal Care" />
      <Gallery category="Sports & Outdoors" />
    </StyledContainer>
  </Layout>
)

export default BrandsPage
