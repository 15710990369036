import React from "react"
import LogoCard from "./logoCard"
import styled from "styled-components"

import { useAllBrandLogos } from "./use-all-brand-logos"

const StyledDiv = styled.div`
  --auto-grid-min-size: 12rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
`

const Gallery = ({ category }) => {
  const logos = useAllBrandLogos()
    .filter(node => node.node.category === category)
    .map(({ node: logo }) => (
      <LogoCard
        key={logo.id}
        logoFile={logo.logoFile.childImageSharp.fluid}
        altText={logo.altText}
        siteLink={logo.siteLink}
      />
    ))
  return (
    <section>
      <h2 style={{ borderBottom: "2px solid red", display: "inline-block" }}>
        {category}
      </h2>
      <StyledDiv>{logos}</StyledDiv>
      <hr />
    </section>
  )
}
export default Gallery
