import { useStaticQuery, graphql } from "gatsby"

export const useAllBrandLogos = () => {
  const { allBrandLogosJson } = useStaticQuery(
    graphql`
      query {
        allBrandLogosJson {
          edges {
            node {
              id
              category
              altText
              siteLink
              logoFile {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allBrandLogosJson.edges
}
